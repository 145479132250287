/**
 * Reviews Badge Widget
 * @note Assume that the end user is not using jQuery
 * @note Update the minified version when updates to this file are made
 * @param {Window} window
 */
(function (window) {

    "use strict";

    /**
     * The Bark global
     * @type window.Bark
     */
    var bark = window.Bark = window.Bark || {};

    /**
     * Flag to prevent DOMLoaded functions being called twice (this should never happen)
     * @type boolean
     */
    var domLoaded = false;

    /**
     * The name of the function that adds events to the document
     * @type String Either addEventListener or attachEvent
     */
    var eventHandler = 'addEventListener' in document ? 'addEventListener' : 'attachEvent';

    /**
     * The name of the event that is fired when the document is ready for manipulation
     * @type String Either DOMContentLoaded or onreadystatechange
     */
    var documentReadyEvent = eventHandler === 'addEventListener' ? 'DOMContentLoaded' : 'onreadystatechange';

    /**
     * A list of all of the styles in the system
     * @type CSSStyleDeclaration
     */
    var allStyles = window.getComputedStyle(document.documentElement, '');

    /**
     * The vendor prefix, e.g. webkit
     * @type string
     */
    var vendorPrefix = (Array.prototype.slice.call(allStyles).join('').match(/-(moz|webkit|ms)-/) || (allStyles.OLink === '' && ['', 'o']))[1];

    /**
     * Constants
     * @type object
     */
    var consts = {
        /**
         * Width of the large badge
         */
        BADGE_WIDTH_LARGE: 256,
        /**
         * Width of the medium badge
         */
        BADGE_WIDTH_MEDIUM: 192,
        /**
         * Width of the small badge
         */
        BADGE_WIDTH_SMALL: 128,
        /**
         * The size of the font to use
         */
        FONT_SIZE_LARGE: 18,
        /**
         * The size of the font to use
         */
        FONT_SIZE_MEDIUM: 16,
        /**
         * The smaller font-size to use when too much space is being taken up
         */
        FONT_SIZE_SMALL: 12,
        /**
         * Minimum height that the large badge has to be
         */
        MIN_BADGE_HEIGHT_LARGE: 82,
        /**
         * Minimum height that the medium badge has to be
         */
        MIN_BADGE_HEIGHT_MEDIUM: 60,
        /**
         * Minimum height that the small badge has to be
         */
        MIN_BADGE_HEIGHT_SMALL: 43
    };

    /**
     * A cache of the widget data keyed by seller id
     * @type object
     */
    var cache = {pro: {}, reviews: {}, verified: {}, coe: {}};

    /**
     * Initialize Bark.sellers if it is not already
     */
    bark.sellers = bark.sellers || {};

    /**
     * Determine whether an item is a DOM Element or not
     * @param {mixed} variable The variable to test
     * @returns {boolean} <b>True</b> if the tested variable is a DOM element
     */
    function isDomElement(variable) {
        // Match a string in the form '[object HTMLxxxElement]', e.g. '[object HTMLDivElement]'
        return Object.prototype.toString.call(variable).match(/^\[object HTML.+Element\]$/);
    }
    function getHostnameFromCurrentName(){
        return location.hostname.match(/^([a-zA-Z0-9\-]+)(\.d)?\.bark\.com/) ? location.hostname : 'www.bark.com';
    }

    /**
     * A minified adaption of jQuery with only the required functions
     * @param {string|DOMElement} selector The selector or a DOMElement
     * @param {DOMElement} scope [optional] The scope in which to look for the selector
     * @returns {object} The Factory
     */
    function f(selector, scope) {
        var nodeList;
        if (scope && ('find' in scope && 'nodes' in scope && 'append' in scope)) {
            // Most likely this factory
            scope = scope.nodes[0];
        } else if (!scope) {
            // By default, scope to the document
            scope = document;
        }
        if (isDomElement(selector)) {
            // The caller has passed a DOMElement as the selector. It needs to be converted into a NodeList object
            // Start of by creating a temp key that will be applied, used to select and then removed
            var key = 'barkfactory' + (new Date().getTime()) + parseInt(Math.random());
            selector.setAttribute(key, true);
            nodeList = scope.querySelectorAll('[' + key + ']');
            selector.removeAttribute(key);
        } else {
            nodeList = scope.querySelectorAll(selector);
        }
        return {
            /**
             * Append an item the current DOMElement list
             * @param {string|DOMElement} html The item to append to a DOMNode
             * @returns {object} The Factory
             */
            append: function (html) {
                this.each(function () {
                    isDomElement(html) ? this.appendChild(html) : this.innerHTML += html;
                });
                return this;
            },
            /**
             * Add styles to the current list of DOMElements
             * @param {object} styles An object of styles
             * @returns {object} The Factory
             */
            css: function (styles) {
                for (var i = 0; i < this.length; i++) {
                    // Go through each item in the DOMNodeList
                    var DOMElement = this.nodes[i];
                    var x;
                    for (x in styles) {
                        // And then apply each of the styles specified
                        // DOMElement.style doesn't understand xxx-yyy syntax. Convert to xxxYyy
                        var jsKey = x.replace(/-([a-zA-Z])/g, function (str) {
                            return str[1].toUpperCase();
                        });
                        // Determine what the vendor-specific variant for this style will be in case it is needed
                        var vendorKeyCSS = vendorPrefix + jsKey[0].toUpperCase() + jsKey.substr(1);
                        var key = jsKey in DOMElement.style ? jsKey : (vendorKeyCSS in DOMElement.style ? vendorKeyCSS : jsKey);
                        DOMElement.style[key] = styles[x];
                    }
                }
                return this;
            },
            /**
             * Set the the HTML attribute for an element
             * @param {string} key The key of the attribute name
             * @param {string} value The value of the attribute name
             * @returns {object} TThe value of the html attribute
             */
            setAttribute: function(key, value) {
                return this.nodes[0].setAttribute(key, value);
            },
            /**
             * Get an html attribute
             * @param {string} key The name of the attribute
             * @returns {string} The value of the html attribute
             */
            attribute: function(key) {
                return this.nodes[0].getAttribute(key);
            },
            /**
             * Get a data attribute
             * @param {string} key The name of the data attribute (without the leading data-
             * @returns {string} The value of the data attribute
             */
            data: function (key) {
                try {
                    return this.nodes[0].getAttribute('data-' + key) || undefined;
                } catch (exception) {
                    return undefined;
                }
            },
            /**
             * Iterate through each DOMElement in the list
             * @param {Function} callback The callback to run for each item
             */
            each: function (callback) {
                for (var i = 0; i < this.length; i++) {
                    callback ? callback.call(this.nodes[i], i) : null;
                }
            },
            /**
             * Find an element within this scope
             * @param {string} selector A CSS2+ selector for a DOM Element
             * @returns {object} The Factory
             */
            find: function (selector) {
                return f(selector, this.nodes[0]);
            },
            /**
             * Set the inner HTML for this collection
             * @param {string|DOMElement} html The HTML to set
             * @returns {object} The Factory
             */
            html: function (html) {
                var newInnerHTML = html;
                if (isDomElement(html)) {
                    newInnerHTML = html.outerHTML;
                }
                this.each(function () {
                    this.innerHTML = newInnerHTML;
                });
                return this;
            },
            length: nodeList.length,
            nodes: nodeList,
            scope: scope,
            /**
             * Get the inner text for this DOMElement
             * @returns {string}
             */
            text: function () {
                return ((this.nodes[0] || {}).textContent || '');
            },
            /**
             * Get the height of the first element in the node list
             * @returns {float}
             */
            width: function () {
                return this.nodes[0].getBoundingClientRect().width;
            }
        };
    }

    /**
     * Add review specific elements to the badge
     * @param {f} t A factory for a DOMElement
     * @param {string} size One of large, medium or small
     * @param {string} sellerHashId The hash id for a seller
     * @param {object} data An object describing the review
     * @param {string} name The name of the seller
     */
    function addReviewsSpecificElements(t, size, sellerHashId, data, name, version) {

        if (typeof version === 'undefined') {
            version = '2.0';
        }

        var href = t.nodes[0].href;
        var domain = getHostnameFromCurrentName();
        var nrtext = ((data.nreviews > 1000 ? '1K+' : data.nreviews) || 'No') + ' review' + (data.nreviews === 1 ? '' : 's');
        var numReviews = (data.nreviews && data.showingstars && size === 'large') ? '<span style="color: #1C2C3B;">' + nrtext + '</span>' : '';
        var vAlign = size === 'small' ? 'top' : 'middle';
        var stars = data.image ? '<img style="display: inline; max-height:' + (size === 'small' ? 12 : 18) + 'px;max-width:80%;vertical-align:' + vAlign + ';border:none;" src="' + data.image + '" />' : '';
        var container = t.find('.bark-top-container');
        var logo;
        if (href) {
            t.setAttribute('href', href + '?show_reviews=true&utm_medium=referral&utm_source=' + sellerHashId + '&utm_campaign=seller-review-widget&utm_content=seller-review-widget');
        }
        if (!stars) {
            stars = '<p>Review me on Bark</p>';
        }
        if(version === '3.0'){
            container.append('<div class="bark-logo"><img src="https://d18jakcjgoan9.cloudfront.net/img/frontend-v2/settings/badges/Badgereviews-sm2.png"/></div>');
        }
        else{
            container.append('<div class="bark-logo"><img src="https://s3-eu-west-1.amazonaws.com/eu-bark-media/bark-logo-small.png"/></div>');
        }
        container.append('<div class="bark-reviews-stars">' + stars + numReviews + '</div>');
        logo = t.find('.bark-logo');
        logo.css({margin: '15px auto 10px', color: '#1C2C3B'});
        t.find('.bark-logo img').css({border: 'none', height: '20px', width: 'auto', display: 'inline'});
        t.find('.bark-reviews-stars').css({left: 0, width: '100%', margin: size === 'medium' ? '2px 0px' : ''});
        // If the user does not have any reviews style the 'Review me on Bark' text
        t.find('.bark-reviews-stars p').css({fontFamily: '"Helvetica Neue", Helvetica, Helvetica, Arial, sans-serif', color: '#133C60',
            fontWeight: 'normal', fontSize: '18px', margin: 0, textDecoration: 'none'
        });
        if (size === 'large') {
            f('.bark-reviews-stars span', t).css({ fontSize: '16px', margin: '3px 0 0 5px', width: '100%', color: '#1b2c3a',
                verticalAlign: 'middle', whiteSpace: 'nowrap'
            });
        }
        if (size !== 'large') {
            logo.css({marginTop: '10px', marginBottom: '0px'});
            t.find('.bark-reviews-stars p').css({ fontSize: '16px' });
        }
        if (size === 'small') {
            logo.css({margin: '3px auto 4px'});
            t.find('.bark-reviews-stars p').css({marginTop: '-4px', fontSize: '12px' });
        }
        this.setAttribute('title', name + ' has ' + nrtext + ' on Bark.com');
    }

    function addGoldClubSpecificElements(t, size, sellerHashId, version) {

        if (typeof version === 'undefined') {
            version = '2.0';
        }

        var container = t.find('.bark-top-container');
        var href = t.nodes[0].href;
        if(version === '3.0'){
            container.append('<img src="http://d1w7gvu0kpf6fl.cloudfront.net/img/frontend-v2/settings/badges/bark-verified-' + size + '.png"/>');
            container.find('img').css({
                marginTop: (size === 'large' ? 16 : (size === 'medium' ? 11 : 6)) + 'px',
                height: (size === 'large' ? 50 : (size === 'medium' ? 38 : 25)) + 'px', border: 'none', display: 'inline'
            });
        }else{
            container.append('<img src="http://d1w7gvu0kpf6fl.cloudfront.net/img/frontend-v2/settings/badges/bark-verified-' + size + '.png"/>');
            container.find('img').css({
                marginTop: (size === 'large' ? 16 : (size === 'medium' ? 11 : 6)) + 'px',
                height: (size === 'large' ? 50 : (size === 'medium' ? 40 : 30)) + 'px', border: 'none', display: 'inline'
            });
        }
        if (href) {
            t.setAttribute('href', href + '?utm_medium=referral&utm_source=' + sellerHashId + '&utm_campaign=seller-pro-widget&utm_content=seller-pro-widget');
        }
    }
    /**
     * Add pro-badge specific elements to the badge
     * @param {f} t A factory for a DOMElement
     * @param {string} size Either large, small or medium
     * @param {string} sellerHashId The hash id for a given seller
     */
    function addProSpecificElements(t, size, sellerHashId, version) {

        if (typeof version === 'undefined') {
            version = '2.0';
        }

        var container = t.find('.bark-top-container');
        var href = t.nodes[0].href;
        if(version === '3.0'){
            container.append('<img src="https://d1w7gvu0kpf6fl.cloudfront.net/img/frontend-v2/settings/badges/bark-pro-' + size + '.png"/>');
            container.find('img').css({
                marginTop: (size === 'large' ? 16 : (size === 'medium' ? 11 : 6)) + 'px',
                height: (size === 'large' ? 50 : (size === 'medium' ? 40 : 30)) + 'px', border: 'none', display: 'inline'
            });
        }else{
            container.append('<img src="https://s3-eu-west-1.amazonaws.com/eu-bark-media/bark-pro-' + size + '.png"/>');
            container.find('img').css({
                marginTop: (size === 'large' ? 16 : (size === 'medium' ? 11 : 6)) + 'px',
                height: (size === 'large' ? 50 : (size === 'medium' ? 40 : 30)) + 'px', border: 'none', display: 'inline'
            });
        }
        if (href) {
            t.setAttribute('href', href + '?utm_medium=referral&utm_source=' + sellerHashId + '&utm_campaign=seller-pro-widget&utm_content=seller-pro-widget');
        }
    }

    function addCertificateOfExcellenceElements(t, size, sellerHashId, version, data) {

        if (typeof version === 'undefined') {
            version = '2.0';
        }

        var container = t.find('.bark-top-container');
        var href = t.nodes[0].href;
        var year = (data || {}).year || t.data('year') || 2019;
        container.append('<img src="https://d18jakcjgoan9.cloudfront.net/img/badges/cert-excellence-' + year + '-' + size + '.png"/>');
        container.find('img').css({
            width: (size === 'large' ? 248 : (size === 'medium' ? 184 : 122)) + 'px',
            height: (size === 'large' ? 82 : (size === 'medium' ? 60 : 43)) + 'px',
            border: 'none',
            display: 'inline'
        });
        if (href) {
            t.setAttribute('href', href + '?utm_medium=referral&utm_source=' + sellerHashId + '&utm_campaign=coe-widget&utm_content=coe-widget');
        }
    }

    /**
     * Render the reviews badge
     */
    bark.renderBadges = function () {
        f('.bark-widget').each(function () {
            var t = f(this);
            var type = t.data('type') || 'pro';
            var hrefBits = this.pathname.split('/');
            var id = hrefBits[hrefBits.length - 2];
            var data = cache[type][id] || {}; // An object that should have the properties name, image (base64), nreviews (int) and showingstars (bool)
            var version = t.data('version');
            var imageDesc = t.data('image').split('-');
            var size = imageDesc[0];  // One of small, medium or large
            var color = imageDesc[1];    // The master color, either gold or navy
            var bgcolor = color === 'navy' ? '#213344' : '#F4BF3D';   // The color of the background
            var bocolor = color === 'navy' ? '#1C2C3B' : '#DDAD33';   // The color of the border
            var bosize = (size === 'small' ? '3' : '4') + 'px';
            var nameattr = t.attribute('data-name');                  // In case the badge is rendered multiple, get the name from an attribute
            var name = data.name || nameattr || this.innerText || ''; // The name of the company
            var nameDiv;
            var width = (size === 'large' ? consts.BADGE_WIDTH_LARGE : (size === 'medium' ? consts.BADGE_WIDTH_MEDIUM : consts.BADGE_WIDTH_SMALL));
            var fontSizeKey = 'FONT_SIZE_' + size.toUpperCase();
            if (!nameattr) {
                // Ensure that the data-name attribute is only set once
                this.setAttribute('data-name', name);
            }
            t.html('').css({display: 'block', position: 'relative', 'font-smoothing': 'subpixel-antialiased', textAlign: 'center',
                overflow: 'hidden', width: width + 'px', border: 'solid ' + bosize + ' ' + bocolor,
                fontFamily: '"Helvetica Neue", Helvetica, Helvetica, Arial, sans-serif', textDecoration: 'none'
            });
            t.append('<div class="bark-top-container"></div>');
            t.find('.bark-top-container').css({height: consts['MIN_BADGE_HEIGHT_' + size.toUpperCase()] + 'px', overflow: 'hidden',
                backgroundColor: '#FFF'
            });
            if (type === 'reviews') {
                addReviewsSpecificElements.call(this, t, size, id, data, name, version);
            } else if (type === 'coe') {
                addCertificateOfExcellenceElements.call(this, t, size, id, version, data);
            } else if (type === 'verified' && !data.disabled) {
                addGoldClubSpecificElements.call(this, t, size, id, version);
            } else if (type !== 'verified') {
                addProSpecificElements.call(this, t, size, id, version);
            }
            nameDiv = t.append('<div class="bark-review-badge-name"><span>' + name + '</span></div>').find('.bark-review-badge-name').css({
                color: '#FFF', width: '100%', '-webkit-font-smoothing' : 'antialiased', padding: '10px ' + (size === 'large' ? '20px' : '10px'),
                fontWeight: 'normal', boxSizing: 'border-box', textDecoration: 'none',
                lineHeight: (consts[fontSizeKey] + 2) + 'px', fontSize: consts[fontSizeKey] + 'px', textOverflow: 'ellipsis',
                backgroundColor: bgcolor, borderTop: 'solid ' + bosize + ' ' + bocolor
            });
            if ('lineClamp' in allStyles || vendorPrefix + 'LineClamp' in allStyles) {
                // Only attempt to apply the line clamp if it is supported
                nameDiv.find('span').css({lineClamp: 3, boxOrient: 'vertical', display: '-' + vendorPrefix + '-box'});
            } else {
                nameDiv.find('span').css({display: 'block'});
            }
            nameDiv.find('span').css({overflow: 'hidden', maxHeight: ((consts[fontSizeKey] + 2) * 3) + 'px'});
        });
    };

    /**
     * Document ready callback
     */
    function docReady() {
        f('.bark-widget').each(function () {
            var script;
            var t = f(this);
            var domain = getHostnameFromCurrentName();
            var pathname = this.pathname + (this.pathname.substr(-1) === '/' ? '' : '/');
            var hrefBits = pathname.split('/');
            var id = t.data('id') || hrefBits[hrefBits.length - 2];
            var type = t.data('type') || 'pro'; // Either reviews or pro
            var funcName = 'barkBadge' + parseInt(Math.random() * 1000000);
            var version = t.data('version') || '1.0';
            if (id in cache[type]) {
                // Data has already been cached for this item
                bark.renderBadges();
                return;
            }
            cache[type][id] = {};
            script = document.createElement('script');
            script.async = true;
            script.src = 'https://' + domain + '/' + type + '-badge/' + id + '/?version=' + version + '&callback=Bark.sellers.' + funcName;
            document.getElementsByTagName('head')[0].appendChild(script);
            bark.sellers[funcName] = function (data) {
                cache[type][id] = data;
                bark.renderBadges();
            };
        });
    }

    /**
     * Vanilla $(document).ready()
     */
    document[eventHandler](documentReadyEvent, function () {
        try {
            // Wrap in a try catch to prevent breaking someone else's website breaking
            if (domLoaded) {
                return;
            }
            domLoaded = true;
            if (documentReadyEvent === 'onreadystatechange' && document.readyState === 'complete') {
                // IE <= 8
                document.detachEvent('onreadystatechange', arguments.callee);
                docReady();
                return;
            }
            docReady();
        } catch (ex) {
            console.error(ex.message);
            console.trace();
        }
    });


}(this));
